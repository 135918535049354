




















































































import { InputSetups } from "@/mixins/input-setups";
import ModuleConfigSettingsWrapper from "@/components/Modules/components/ModuleConfigSettingsWrapper.vue";
import GoBackButton from "@/components/Modules/components/PageTitleButtons/GoBackButton.vue";
import SaveModuleButton from "@/components/Modules/components/PageTitleButtons/SaveModuleButton.vue";
import ModuleConfigSettings from "@/components/Modules/components/ModuleConfigSettings.vue";
import ModuleBindings from "@/includes/logic/Modules/mixins/ModuleBindings";
import EnterBindedChannelHandlerModuleBuilder
  from "@/includes/logic/Modules/models/modules/EnterBindedChannelHandlerModule/EnterBindedChannelHandlerModuleBuilder";
import PunishActionSetting from "@/components/Modules/punish-actions/components/PunishActionSetting.vue";
import BindChannel from "@/components/Modules/components/facecontrol/BindChannel.vue";
import NotAvailableOptionsOverlay from "@/components/NotAvailableOptionsOverlay.vue";
import BindChannelInfo from "@/components/Modules/components/facecontrol/BindChannelInfo.vue";

import SwitchInput from "piramis-base-components/src/components/Pi/fields/SwitchInput/SwitchInput.vue";

import { Component, VModel, Mixins } from 'vue-property-decorator';

@Component({
  components: {
    BindChannelInfo,
    NotAvailableOptionsOverlay,
    BindChannel,
    PunishActionSetting,
    ModuleConfigSettingsWrapper,
    ModuleConfigSettings,
    SaveModuleButton,
    GoBackButton,
    SwitchInput
  }
})
export default class EnterBindedChannelHandlerModuleView extends Mixins(InputSetups, ModuleBindings) {
  @VModel() module!: EnterBindedChannelHandlerModuleBuilder

  get tariffTag() {
    return this.module.tariffTags()
  }

  channelBindWarning(value: number | string): void {
    this.$confirm({
      title: this.$t('pi_warning').toString(),
      content: this.$t('module_binded_channel_confirm_text').toString(),
      okText: this.$t('do_save_chat_config').toString(),
      cancelText: this.$t('pi_reject').toString(),
      onOk: () => {
        this.module.model.params.binded_channel_id = +value

        this.saveModule()
      }
    })
  }

  unbind() {
    this.module.model.config.enabled = false
    this.saveModule()
  }

  bindFaceControlChannel(channelId: number | undefined): void {
    if (channelId) {
      this.channelBindWarning(channelId)
    }
  }

  mounted() {
    if (this.tariffTag) {
      this.$baseTemplate.saveButton.hide()
    }
  }
}
