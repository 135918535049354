import { render, staticRenderFns } from "./BindChannel.vue?vue&type=template&id=fa820332&scoped=true&"
import script from "./BindChannel.vue?vue&type=script&lang=ts&"
export * from "./BindChannel.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fa820332",
  null
  
)

export default component.exports